@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;800&display=swap');

.slick-prev {
    left: 2% !important;
    background-color: #c4c4c4 !important;
    border-radius: 50%;
    top: 40% !important;
    z-index: 1;
    color: black !important
}

.slick-next {
    right: 2% !important;
    border-radius: 50%;
    background-color: #c4c4c4 !important;
    z-index: 1;
    top: 40% !important;
}

.content-slide .items-slide {
    margin: 0 auto;
}

.slick-disabled {
    display: none !important;
}

/* .ant-list-item-meta-title {
    line-height: 5px;
    margin-bottom: -5px !important;
    font-weight: 500 !important;
}

.ant-list-item-meta-description {
    line-height: 5px;
    font-weight: 500 !important;
    color: #c4c4c4 !important
} */

body {
    background: #F4F8FB !important;
}

/* .nav-link.active {
    border-radius: 8px;
} */
/* .nav.nav-tabs {
    border-radius: 8px;
} */