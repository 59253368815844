.card-login {
	width: 404px;
	border-radius: 12px;
}

label {
	color: #212529;
	font-size: 16px;
}

body {
	background: #fcfcfd;
}

.inputpass:focus {
	box-shadow: none;
	border: 1px solid #ced4da;
}
