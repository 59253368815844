.ant-table-thead>tr>th {
    color: #c4c4c4;
    background-color: transparent !important;
    font-size: 12px;
    font-weight: 600
}

.card-container p {
    margin: 0;
}

/* .card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
} */

.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
}

.card-container>.ant-tabs-card>.ant-tabs-nav::before {
    display: none;
}

.card-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

.ant-tabs-tab-btn {
    color: black
}

.card-container>.ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
    background: #c4c4c4;
    border-color: transparent;
    border-radius: 25px !important;
}



/* [data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
} */

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    background: #141414;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
}

/* bootstrap tabs */

.cursor-pointer {
    cursor: pointer;
}