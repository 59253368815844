/* .ant-steps-item-process .ant-steps-item-icon {
    background: red !important;
} */

.ant-steps-item-process .ant-steps-item-icon {
	background: #084298 !important;
	border: 0px !important;
	/* width: 40px */
	/* padding: 6px */
	/* align-items: center; */
}

/* div.ant-steps-item-container {
    width: 70px
} */

div.ant-steps-item-icon {
	line-height: 20px !important;
	padding: 6px;
	width: 36px;
	background: #e7f1ff !important;
	border: 0px !important;
	font-weight: 700 !important;
	font-family: "Poppins" !important;
	/* font-color: #fff !important; */
}

div.ant-steps-item-tail {
	display: none;
}

.ant-steps-item-title {
	font-weight: 500 !important;
}

hr.s {
	color: #e9ecef;
	border: 1px solid #e9ecef;
}

.inputpass:focus {
	box-shadow: none;
	border: 1px solid #ced4da;
}
