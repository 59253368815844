/* font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;800&display=swap');
/* @import 'custom.scss'; */

.Radius {
  border-radius: 25px;
}

.Login-attribute {
  font-family: 'Poppins';
}

.Login-attribute h3 {
  margin-top: 15px;
  font-weight: 600;
  font-size: 33px;
}

.text-span {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.text-span p {
  width: 423px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #C4C4C4;

}

hr.garis {
  /* width: 50%; */
  margin: auto;
  margin-bottom: 15px;
  padding: 0 20px 0 20px;
}

.garis {
  width: 537px;
  align-items: center;
}
